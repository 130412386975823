import * as React from "react";
import {
    Link as RouterLink,
    Navigate,
    useNavigate,
    useSearchParams,
} from "react-router";

import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import {
    AuthError,
    getAuth,
    signInWithPopup,
    GoogleAuthProvider,
    OAuthProvider,
} from "firebase/auth";

import { fetchApi } from "../../util";
import {
    AppleBlackSignInButton,
    GoogleSignInButton,
    VolleyButton,
} from "../common/buttons";
import { useCurrentUser } from "../hooks/currentUser";

import SignupLoadingButton from "./SignupLoadingButton";
import TermsDisclaimer from "./TermsDisclaimer";

const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com");

export default function SignupEmail(): React.JSX.Element {
    const { currentUser } = useCurrentUser();
    const theme = useTheme();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [email, setEmail] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    const onChangeEmail = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            if (errorMessage) {
                setErrorMessage("");
            }
            setEmail(e.currentTarget.value);
        },
        [errorMessage],
    );

    const navigateUrl = React.useMemo(() => {
        const invite = searchParams.get("invite");
        if (invite) {
            return `../account?invite=${invite}`;
        }

        return "../account";
    }, [searchParams]);

    const onSubmit = React.useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            setLoading(true);
            const trimmedEmail = email.trim();

            try {
                const { exists } = await fetchApi<{ exists: boolean }>(
                    `/api/signup/users/email/${encodeURIComponent(trimmedEmail)}`,
                    "GET",
                );

                if (exists) {
                    setErrorMessage(
                        "Sorry, we can't create an account for this email address.",
                    );
                    return;
                }
            } catch (err: unknown) {
                setErrorMessage((err as Error).message);
                return;
            } finally {
                setLoading(false);
            }
            navigate(navigateUrl, { state: { email: trimmedEmail } });
        },
        [email, navigateUrl, navigate],
    );

    const onClickSignUpWithApple = React.useCallback(async () => {
        setLoading(true);

        try {
            const { user } = await signInWithPopup(getAuth(), appleProvider);
            const state = {
                name: user.displayName,
                email: user.email,
            };
            navigate(navigateUrl, { state });
        } catch (e: unknown) {
            setErrorMessage((e as AuthError).message);
            setLoading(false);
        }
    }, [navigateUrl, navigate]);

    const onClickSignUpWithGoogle = React.useCallback(async () => {
        setLoading(true);

        try {
            const { user } = await signInWithPopup(getAuth(), googleProvider);
            const state = {
                name: user.displayName,
                email: user.email,
            };
            navigate(navigateUrl, { state });
        } catch (e: unknown) {
            setErrorMessage((e as AuthError).message);
            setLoading(false);
        }
    }, [navigateUrl, navigate]);

    if (currentUser) {
        return <Navigate to="/" replace />;
    }

    return (
        <Container maxWidth="xs">
            <Toolbar />
            <Grid container>
                <Grid size={9}>
                    <Typography variant="h3" component="h2">
                        Create an account
                    </Typography>
                </Grid>
                <Grid size={3}>
                    <Typography variant="h5" component="h3" sx={{ mt: 1 }}>
                        or{" "}
                        <Link
                            underline="none"
                            color={theme.palette.info.main}
                            component={RouterLink}
                            to="/login"
                        >
                            Log In
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1" sx={{ py: 1 }}>
                Create an account with your email or sign up using a 3rd party
                service.
            </Typography>
            <Stack mt={2} spacing={4} component="form" onSubmit={onSubmit}>
                <TextField
                    type="email"
                    autoComplete="email"
                    name="email"
                    label="Email"
                    placeholder={
                        email.length ? "" : "Enter your email to get started"
                    }
                    value={email}
                    onChange={onChangeEmail}
                    fullWidth
                    error={!!errorMessage}
                    helperText={errorMessage}
                />
                <SignupLoadingButton loading={loading}>
                    <VolleyButton
                        type="submit"
                        disabled={loading || !/^\S+@\S+\.\S+$/.test(email)}
                    >
                        Continue
                    </VolleyButton>
                </SignupLoadingButton>
                <Divider
                    sx={{
                        fontSize: "8pt",
                        color: "#7e95c7",
                        fontWeight: "bold",
                    }}
                >
                    OR
                </Divider>
                <AppleBlackSignInButton
                    type="button"
                    onClick={onClickSignUpWithApple}
                    disabled={loading}
                    disableElevation
                    sx={{
                        textTransform: "none",
                        fontFamily:
                            "-apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif",
                        fontSize: 16,
                    }}
                >
                    <Box
                        component="div"
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <AppleIcon sx={{ mb: 0.5, mr: 0.4 }} />
                        <span>Sign up with Apple</span>
                    </Box>
                </AppleBlackSignInButton>
                <GoogleSignInButton
                    type="button"
                    onClick={onClickSignUpWithGoogle}
                    disabled={loading}
                    disableElevation
                    sx={{
                        textTransform: "none",
                        fontFamily:
                            "-apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif",
                        fontSize: 16,
                    }}
                >
                    <Box
                        component="div"
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <GoogleIcon sx={{ mr: 1 }} />
                        <span>Sign up with Google</span>
                    </Box>
                </GoogleSignInButton>
                <TermsDisclaimer />
            </Stack>
        </Container>
    );
}
