import * as React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router";

import CreditCardIcon from "@mui/icons-material/CreditCard";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from "@stripe/react-stripe-js";

import { fetchApi } from "../../util";
import Loading from "../common/Loading";
import { VolleyButton } from "../common/buttons";
import { STATES } from "../common/data";
import { useCurrentUser } from "../hooks/currentUser";

import SignupLoadingButton from "./SignupLoadingButton";
import SignupPriceSummary from "./SignupPriceSummary";
import StripeInput from "./StripeInput";
import { Invoice } from "./types";

interface SubscriptionData {
    subscriptionId: string;
    paymentClientSecret?: string;
    setupClientSecret?: string;
    customerId: string;
}

type LocationState = Partial<{
    promoCode: string;
    priceId: string;
    invoicePreview: Invoice;
}>;

export default function SignupPayment(): React.JSX.Element {
    const location = useLocation();
    const {
        promoCode,
        priceId,
        invoicePreview: initialInvoicePreview,
    } = (location.state ?? {}) as LocationState;
    const { currentUser, setCurrentUser, refreshSubscriptions } =
        useCurrentUser();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const [address1, setAddress1] = React.useState("");
    const [city, setCity] = React.useState("");
    const [state, setState] = React.useState("");
    const [postalCode, setPostalCode] = React.useState("");
    const [invoicePreview, setInvoicePreview] = React.useState<Invoice | null>(
        initialInvoicePreview ?? null,
    );
    const [subscriptionData, setSubscriptionData] =
        React.useState<SubscriptionData>();
    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    const isFreeForever =
        invoicePreview?.discount?.coupon.percent_off === 100 &&
        invoicePreview.discount.coupon.duration === "forever";

    React.useEffect(() => {
        if (!priceId) {
            navigate("../plans");
        }
    }, [priceId, navigate]);

    const onProceedToCheckout = React.useCallback(
        async (e: React.FormEvent) => {
            e.preventDefault();
            if (!address1 || !city || !state || !postalCode || !elements) {
                setErrorMessage("Please fill out the whole form.");
                return;
            }

            setLoading(true);
            try {
                const invoicePreviewData = await fetchApi<Invoice>(
                    "/api/signup/invoice-preview",
                    "POST",
                    {
                        priceId,
                        address1: address1.trim() || undefined,
                        city: city.trim() || undefined,
                        state: state || undefined,
                        postalCode: postalCode.trim() || undefined,
                        promoCode: promoCode?.trim() || undefined,
                    },
                );
                setInvoicePreview(invoicePreviewData);
                setSearchParams(
                    (current) => {
                        current.set("state", "checkout");
                        return current;
                    },
                    { state: { priceId, promoCode: promoCode?.trim() } },
                );
            } catch (err: unknown) {
                setErrorMessage((err as Error).message);
            } finally {
                setLoading(false);
            }
        },
        [
            priceId,
            promoCode,
            address1,
            city,
            state,
            postalCode,
            elements,
            setSearchParams,
        ],
    );

    const onPurchase = React.useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            setErrorMessage("");
            if (!stripe || !elements || !currentUser) {
                setErrorMessage(
                    "Payment system not yet initialized. Please try again",
                );
                return;
            }

            // Create subscription
            setLoading(true);
            try {
                // if confirming card payment fails (e.g. decline) we don't want to create another
                // subscription, save the created subscription and use that on subsequent payment
                // confirms
                let paymentClientSecret;
                let setupClientSecret;
                if (
                    !subscriptionData?.paymentClientSecret &&
                    !subscriptionData?.setupClientSecret
                ) {
                    const newSubscriptionData =
                        await fetchApi<SubscriptionData>(
                            "/api/signup/subscriptions",
                            "POST",
                            {
                                isFreeForever,
                                priceId,
                                couponId:
                                    invoicePreview?.discount?.coupon.id ??
                                    undefined,
                                address1: address1.trim() ?? undefined,
                                city: city.trim() ?? undefined,
                                state: state ?? undefined,
                                postalCode: postalCode.trim() ?? undefined,
                                promoCode: promoCode?.trim() || undefined,
                            },
                        );
                    // Add the customer ID to the current user now that we have it
                    setCurrentUser({
                        ...currentUser,
                        customerId: newSubscriptionData.customerId,
                    });
                    setSubscriptionData(newSubscriptionData);
                    refreshSubscriptions();
                    paymentClientSecret =
                        newSubscriptionData.paymentClientSecret;
                    setupClientSecret = newSubscriptionData.setupClientSecret;
                } else {
                    paymentClientSecret = subscriptionData.paymentClientSecret;
                    setupClientSecret = subscriptionData?.setupClientSecret;
                }

                // Confirm card payment if necessary (based on presence of `paymentClientSecret`)
                if (paymentClientSecret) {
                    const cardElement = elements.getElement(CardNumberElement);
                    if (!cardElement) {
                        setErrorMessage(
                            "Could not found credit card element. Please try again.",
                        );
                        return;
                    }

                    const { error } = await stripe.confirmCardPayment(
                        paymentClientSecret,
                        {
                            payment_method: {
                                card: cardElement,
                                billing_details: {
                                    name: `${currentUser.firstName} ${currentUser.lastName}`,
                                    email: currentUser.email,
                                    address: {
                                        line1: address1.trim(),
                                        city: city.trim(),
                                        state,
                                        postal_code: postalCode.trim(),
                                        country: "US",
                                    },
                                },
                            },
                        },
                    );

                    if (error) {
                        throw new Error(error.message);
                    }
                    // Confirm payment method setup if there's no payment due
                    // but we're still setting up a payment method for future invoices
                } else if (setupClientSecret) {
                    const cardElement = elements.getElement(CardNumberElement);
                    if (!cardElement) {
                        setErrorMessage(
                            "Could not found credit card element. Please try again.",
                        );
                        return;
                    }

                    const { error } = await stripe.confirmCardSetup(
                        setupClientSecret,
                        {
                            return_url: `${window.location.protocol}//${window.location.host}/`,
                            payment_method: {
                                card: cardElement,
                                billing_details: {
                                    name: `${currentUser.firstName} ${currentUser.lastName}`,
                                    email: currentUser.email,
                                    address: {
                                        line1: address1.trim(),
                                        city: city.trim(),
                                        state,
                                        postal_code: postalCode.trim(),
                                        country: "US",
                                    },
                                },
                            },
                        },
                    );

                    if (error) {
                        throw new Error(error.message);
                    }
                }

                navigate("/", { replace: true });
            } catch (err: unknown) {
                setErrorMessage((err as Error).message);
                setLoading(false);
            }
        },
        [
            priceId,
            elements,
            stripe,
            currentUser,
            address1,
            promoCode,
            city,
            state,
            postalCode,
            navigate,
            subscriptionData?.paymentClientSecret,
            subscriptionData?.setupClientSecret,
            invoicePreview?.discount?.coupon.id,
            setCurrentUser,
            isFreeForever,
            refreshSubscriptions,
        ],
    );

    if (!stripe || !elements) {
        return <Loading />;
    }

    const checkingOut = searchParams.has("state") && invoicePreview;

    return (
        <Container
            maxWidth="xs"
            sx={{ background: "#d5dBed80", minHeight: "100vh" }}
        >
            <Toolbar />
            <Box
                component="form"
                onSubmit={onProceedToCheckout}
                sx={{ display: checkingOut ? "none" : "block" }}
            >
                <Card elevation={3} sx={{ p: 2 }}>
                    <Stack spacing={2}>
                        <Typography variant="h3" gutterBottom>
                            Payment
                        </Typography>
                        {!!errorMessage && (
                            <Typography color="error.main">
                                {errorMessage}
                            </Typography>
                        )}
                        <TextField
                            label="Card Number"
                            name="ccNumber"
                            autoComplete="cc-number"
                            variant="outlined"
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <CreditCardIcon />
                                        </InputAdornment>
                                    ),
                                    inputComponent: StripeInput,
                                    inputProps: {
                                        component: CardNumberElement,
                                    },
                                },

                                inputLabel: { shrink: true },
                            }}
                        />
                        <Stack direction="row" spacing={1}>
                            <TextField
                                label="Expiration"
                                name="expiration"
                                autoComplete="cc-exp"
                                variant="outlined"
                                fullWidth
                                slotProps={{
                                    input: {
                                        inputComponent: StripeInput,
                                        inputProps: {
                                            component: CardExpiryElement,
                                        },
                                    },

                                    inputLabel: { shrink: true },
                                }}
                            />
                            <TextField
                                label="Security Code"
                                name="cvc"
                                autoComplete="cc-csc"
                                variant="outlined"
                                fullWidth
                                slotProps={{
                                    input: {
                                        inputComponent: StripeInput,
                                        inputProps: {
                                            component: CardCvcElement,
                                        },
                                    },

                                    inputLabel: { shrink: true },
                                }}
                            />
                        </Stack>
                        <TextField
                            label="Billing Address"
                            type="text"
                            autoComplete="address-line1"
                            name="address1"
                            id="address1"
                            value={address1}
                            onChange={(e) => setAddress1(e.currentTarget.value)}
                            fullWidth
                        />
                        <TextField
                            label="City"
                            type="text"
                            name="city"
                            id="city"
                            autoComplete="address-level2"
                            value={city}
                            onChange={(e) => setCity(e.currentTarget.value)}
                            fullWidth
                        />
                        <FormControl fullWidth>
                            <InputLabel id="state-label" htmlFor="state">
                                State
                            </InputLabel>
                            <Select
                                label="State"
                                autoComplete="address-level1"
                                value={state ?? ""}
                                inputProps={{ id: "state" }}
                                onChange={(e) => setState(e.target.value)}
                            >
                                {STATES.filter((s) => s.country === "USA").map(
                                    (s) => (
                                        <MenuItem
                                            value={s.abbreviation}
                                            key={s.name}
                                        >
                                            {s.name}
                                        </MenuItem>
                                    ),
                                )}
                            </Select>
                        </FormControl>
                        <TextField
                            label="Zip Code"
                            type="text"
                            name="postal-code"
                            id="postal-code"
                            autoComplete="postal-code"
                            value={postalCode}
                            onChange={(e) =>
                                setPostalCode(e.currentTarget.value)
                            }
                            fullWidth
                        />
                    </Stack>
                </Card>
                <Box component="div" pt={4} pb={8}>
                    <SignupLoadingButton loading={loading}>
                        <VolleyButton type="submit" disabled={loading}>
                            Proceed To Checkout
                        </VolleyButton>
                    </SignupLoadingButton>
                </Box>
            </Box>
            {checkingOut && (
                <Box component="form" onSubmit={onPurchase}>
                    {!!errorMessage && (
                        <Typography color="error.main">
                            {errorMessage}
                        </Typography>
                    )}
                    <Card elevation={4} sx={{ mb: 2, px: 1, py: 2 }}>
                        <Stack spacing={2}>
                            <Typography variant="h3" gutterBottom>
                                Purchase Summary
                            </Typography>
                            <SignupPriceSummary
                                selectedPrice={null}
                                invoicePreview={invoicePreview}
                            />
                        </Stack>
                    </Card>
                    <Box component="div" pt={4} pb={8}>
                        <SignupLoadingButton loading={loading}>
                            <VolleyButton type="submit" disabled={loading}>
                                Purchase
                            </VolleyButton>
                        </SignupLoadingButton>
                    </Box>
                </Box>
            )}
        </Container>
    );
}
