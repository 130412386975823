import * as React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function LineItem({
    label,
    price,
}: {
    label: string;
    price: string;
}): React.JSX.Element {
    return (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
                sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    "&:after": {
                        content:
                            "' ................................................................................'",
                    },
                }}
                component="span"
            >
                {label}
            </Typography>
            <Typography sx={{ flexShrink: 0 }} component="span">
                {price}
            </Typography>
        </Box>
    );
}
