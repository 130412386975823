import * as React from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

interface Props {
    children: React.ReactNode;
    loading?: boolean;
}

export default function SignupLoadingButton({
    children,
    loading = false,
}: Props): React.JSX.Element {
    return (
        <Box sx={{ position: "relative" }}>
            {children}
            {loading && (
                <CircularProgress
                    size={24}
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        mt: "-12px",
                        ml: "-12px",
                    }}
                />
            )}
        </Box>
    );
}
