import * as React from "react";
import {
    Link as RouterLink,
    Navigate,
    useLocation,
    useSearchParams,
    useNavigate,
} from "react-router";

import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";

import type { UserSignupPayload, UserWithContentProviders } from "@volley/data";

import { fetchApi } from "../../util";
import { HomeLocationWithSelfDescribe } from "../common/HomeLocationAutocomplete";
import Password from "../common/Password";
import { VolleyButton } from "../common/buttons";
import { USER_TAG_OPTIONS } from "../common/data";
import { useCurrentUser } from "../hooks/currentUser";

import SignupLoadingButton from "./SignupLoadingButton";
import TermsDisclaimer from "./TermsDisclaimer";

function splitFullName(fullName?: string): [string, string] {
    if (!fullName) {
        return ["", ""];
    }
    const parts = fullName.split(" ");
    return [parts[0], parts.slice(1).join(" ") || ""];
}

interface LocationState {
    email: string;
    name?: string;
}

export default function SignupAccount(): React.JSX.Element {
    const navigate = useNavigate();
    const theme = useTheme();
    const [searchParams] = useSearchParams();
    const { setCurrentUser } = useCurrentUser();
    const location = useLocation();
    const stateUser = location.state as LocationState | null;
    const [first, last] = splitFullName(stateUser?.name);
    const [firstName, setFirstName] = React.useState(first);
    const [lastName, setLastName] = React.useState(last);
    const [password, setPassword] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [optOutOfPhone, setOptOutOfPhone] = React.useState(false);
    const [homeLocationId, setHomeLocationId] = React.useState<number | null>(
        null,
    );
    const [hasSelectedHomeLocation, setHasSelectedHomeLocation] =
        React.useState(false);
    const [selfDescribedHomeLocationName, setSelfDescribedHomeLocationName] =
        React.useState<string | null>(null);
    const [clubRole, setClubRole] = React.useState<string | null>(null);
    const [optIntoMarketing, setOptIntoMarketing] = React.useState(true);
    const [errorMessages, setErrorMessages] = React.useState<string[]>([]);
    const [loading, setLoading] = React.useState(false);
    const isAuthenticated = !!getAuth().currentUser;

    const onSubmit = React.useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            setLoading(true);

            if (!stateUser?.email) {
                setErrorMessages(["Email is required"]);
                return;
            }

            // Only check for duplicate email if the user isn't authenticated
            if (!isAuthenticated) {
                try {
                    const { exists } = await fetchApi<{ exists: boolean }>(
                        `/api/signup/users/email/${encodeURIComponent(stateUser.email)}`,
                        "GET",
                    );

                    if (exists) {
                        setLoading(false);
                        setErrorMessages([
                            "Sorry, we can't create an account for this email address.",
                        ]);
                        return;
                    }
                } catch (err: unknown) {
                    setLoading(false);
                    setErrorMessages([(err as Error).message]);
                    return;
                }
            }

            // Validate
            const errors = [];
            if (!firstName) errors.push("First Name is required");
            if (!lastName) errors.push("Last Name is required");
            if (!isAuthenticated && !password)
                errors.push("Password is required");
            if (!optOutOfPhone && !matchIsValidTel(phone)) {
                errors.push(
                    "Please enter a valid Phone Number or explicity opt out",
                );
            }
            if (!homeLocationId && !hasSelectedHomeLocation)
                errors.push("Please select a Home Court");
            if (!clubRole) errors.push("Please select a Club Role");
            if (errors.length) {
                setLoading(false);
                setErrorMessages(errors);
                return;
            }

            setErrorMessages([]);
            const invite = searchParams.get("invite");
            try {
                const idToken = await getAuth().currentUser?.getIdToken();
                const payload: UserSignupPayload = {
                    idToken,
                    password: password || undefined,
                    email: stateUser.email,
                    firstName: firstName.trim(),
                    lastName: lastName.trim(),
                    phone: optOutOfPhone ? null : phone.trim(),
                    homeLocationId,
                    selfDescribedHomeLocationName,
                    optIntoMarketing,
                    inviteCode: invite ?? undefined,
                    tags: clubRole ? [clubRole] : [],
                };
                const volleyUser = await fetchApi<UserWithContentProviders>(
                    "/api/signup/users",
                    "POST",
                    payload,
                );

                if (!isAuthenticated) {
                    await signInWithEmailAndPassword(
                        getAuth(),
                        stateUser.email,
                        password,
                    );
                }

                setCurrentUser(volleyUser);
                navigate("/", { replace: true });
            } catch (err: unknown) {
                setErrorMessages([(err as Error).message]);
                setLoading(false);
            }
        },
        [
            stateUser?.email,
            firstName,
            lastName,
            password,
            phone,
            optOutOfPhone,
            homeLocationId,
            selfDescribedHomeLocationName,
            hasSelectedHomeLocation,
            clubRole,
            optIntoMarketing,
            isAuthenticated,
            setCurrentUser,
            searchParams,
            navigate,
        ],
    );

    if (!stateUser) {
        return <Navigate to="../email" replace />;
    }

    return (
        <Container maxWidth="xs">
            <Toolbar />
            <Grid container>
                <Grid size={isAuthenticated ? 12 : 9}>
                    <Typography variant="h3" component="h2">
                        {isAuthenticated
                            ? "Confirm Account Details"
                            : "Create an Account"}
                    </Typography>
                </Grid>
                {!isAuthenticated && (
                    <Grid size={3}>
                        <Typography variant="h5" component="h3" sx={{ mt: 1 }}>
                            or{" "}
                            <Link
                                underline="none"
                                color={theme.palette.info.main}
                                component={RouterLink}
                                to="/login"
                            >
                                Log In
                            </Link>
                        </Typography>
                    </Grid>
                )}
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body2" sx={{ my: 2 }}>
                Let’s make an account for{" "}
                <Typography
                    sx={{ color: theme.palette.info.main }}
                    component="span"
                >
                    {stateUser?.email ?? ""}
                </Typography>
            </Typography>
            {errorMessages.map((message) => (
                <Typography key={message} color="error.main" gutterBottom>
                    {`• ${message}`}
                </Typography>
            ))}
            <Stack
                spacing={4}
                component="form"
                onSubmit={onSubmit}
                sx={{ mt: 2, pb: 8 }}
            >
                <TextField
                    type="text"
                    id="first-name"
                    name="first-name"
                    autoComplete="given-name"
                    label="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.currentTarget.value)}
                    fullWidth
                />
                <TextField
                    type="text"
                    id="last-name"
                    name="last-name"
                    autoComplete="family-name"
                    label="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.currentTarget.value)}
                    fullWidth
                />
                <FormGroup>
                    <MuiTelInput
                        autoComplete="tel"
                        defaultCountry="US"
                        onlyCountries={["US", "CA"]}
                        forceCallingCode
                        disableDropdown
                        id="phone"
                        name="phone"
                        label="Phone Number"
                        value={optOutOfPhone ? "" : phone}
                        disabled={optOutOfPhone}
                        onChange={(val: string) => setPhone(val)}
                        helperText="Your number helps us reach out to help with the trainer for support."
                        fullWidth
                    />
                    <FormControlLabel
                        labelPlacement="end"
                        control={
                            <Checkbox
                                checked={optOutOfPhone}
                                onChange={() =>
                                    setOptOutOfPhone(!optOutOfPhone)
                                }
                                name="optOutOfPhoneNumber"
                            />
                        }
                        label={
                            <Typography variant="caption">
                                No thanks, I’ll reach out to you if I need
                                assistance.
                            </Typography>
                        }
                    />
                </FormGroup>
                {!isAuthenticated && (
                    <Password onPasswordChange={setPassword} />
                )}
                <HomeLocationWithSelfDescribe
                    homeLocationId={homeLocationId}
                    onChange={(value) =>
                        setHomeLocationId(value?.homeLocation?.id ?? null)
                    }
                    selfDescribedName={selfDescribedHomeLocationName}
                    onChangeSelfDescribedName={(value) =>
                        setSelfDescribedHomeLocationName(value)
                    }
                    hasSelected={hasSelectedHomeLocation}
                    setHasSelected={setHasSelectedHomeLocation}
                />
                <FormControl>
                    <InputLabel id="clubRole">Club Role</InputLabel>
                    <Select
                        labelId="clubRole"
                        id="clubRole"
                        value={clubRole || ""}
                        label="Club Role"
                        onChange={({ target: { value } }) => setClubRole(value)}
                    >
                        {USER_TAG_OPTIONS.map((t) => (
                            <MenuItem key={t} value={t}>
                                {t}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControlLabel
                    labelPlacement="end"
                    sx={{ alignItems: "start" }}
                    control={
                        <Checkbox
                            checked={optIntoMarketing}
                            onChange={() =>
                                setOptIntoMarketing(!optIntoMarketing)
                            }
                            name="optIntoMarketing"
                            sx={{ paddingTop: 0 }}
                        />
                    }
                    label={
                        <Typography variant="caption">
                            Please let me know about special offers and the most
                            exciting news by dropping me an email.
                        </Typography>
                    }
                />
                <SignupLoadingButton loading={loading}>
                    <VolleyButton type="submit" disabled={loading}>
                        Continue
                    </VolleyButton>
                </SignupLoadingButton>

                <TermsDisclaimer />
            </Stack>
        </Container>
    );
}
