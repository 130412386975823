export const PRICE_LABEL_MAP: Record<string, string> = {
    platform_tennis_daily: "Daily",
    platform_tennis_monthly: "Monthly",
    platform_tennis_yearly: "Seasonal (Annual)",
};

export interface Invoice {
    automatic_tax: { status: "requires_location_inputs" | "complete" };
    total_discount_amounts: {
        amount: number;
    }[];
    discount: {
        id: string;
        coupon: {
            id: string;
            amount_off: number | null;
            duration: string;
            duration_in_months: 8;
            name: string;
            percent_off: number | null;
        };
    } | null;
    lines: {
        data: {
            price: { lookup_key: string };
        }[];
    };
    tax: number;
    subtotal: number;
    total: number;
    period_end: number;
    currency: string;
}

export interface Price {
    id: string;
    currency: string;
    product: { name: string };
    recurring: {
        interval: string;
    };
    unit_amount: number;
    lookup_key:
        | "platform_tennis_daily"
        | "platform_tennis_monthly"
        | "platform_tennis_yearly";
}

export interface PricesData {
    platform_tennis_daily: Price;
    platform_tennis_monthly: Price;
    platform_tennis_yearly: Price;
}

export interface Config {
    publishableKey: string;
    prices: PricesData;
}
