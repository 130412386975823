import * as React from "react";

import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import LineItem from "./LineItem";
import { Invoice, PRICE_LABEL_MAP, Price } from "./types";

interface Props {
    selectedPrice: Price | null;
    invoicePreview: Invoice | null;
}

export default function SignupPriceSummary({
    invoicePreview,
    selectedPrice,
}: Props): React.JSX.Element | null {
    const currencyFormatter = React.useMemo(
        () =>
            new Intl.NumberFormat(undefined, {
                style: "currency",
                currency: invoicePreview?.currency ?? "usd",
            }),
        [invoicePreview?.currency],
    );

    let billedLabel = "Total";
    if (selectedPrice?.recurring.interval === "month") {
        billedLabel = "Billed Monthly";
    } else if (selectedPrice?.recurring.interval === "year") {
        billedLabel = "Billed Annually";
    }

    let paymentAfterDiscountPeriod = "";
    const duration = invoicePreview?.discount?.coupon.duration_in_months;
    if (duration) {
        const amount = currencyFormatter.format(invoicePreview.subtotal / 100);
        const firstPaymentEstimate = `${amount} (plus applicable taxes)`;
        paymentAfterDiscountPeriod = `You will be charged an estimated ${firstPaymentEstimate} in ${duration} months.`;
    }

    if (invoicePreview) {
        return (
            <>
                <LineItem
                    label={
                        PRICE_LABEL_MAP[
                            invoicePreview.lines.data[0].price.lookup_key
                        ] ?? ""
                    }
                    price={currencyFormatter.format(
                        invoicePreview.subtotal / 100,
                    )}
                />
                {invoicePreview.discount && (
                    <LineItem
                        label="Discounts Applied"
                        price={`(${currencyFormatter.format(
                            invoicePreview.total_discount_amounts[0].amount /
                                100,
                        )})`}
                    />
                )}
                {invoicePreview.tax > 0 && (
                    <LineItem
                        label="Tax"
                        price={currencyFormatter.format(
                            invoicePreview.tax / 100,
                        )}
                    />
                )}
                <Divider sx={{ border: "1px solid black" }} />
                <LineItem
                    label={billedLabel}
                    price={currencyFormatter.format(invoicePreview.total / 100)}
                />
                {paymentAfterDiscountPeriod && (
                    <Typography variant="body2" sx={{ py: 2 }}>
                        {paymentAfterDiscountPeriod}
                    </Typography>
                )}
            </>
        );
    }

    if (selectedPrice) {
        return (
            <LineItem
                label={billedLabel}
                price={currencyFormatter.format(
                    selectedPrice.unit_amount / 100,
                )}
            />
        );
    }

    return null;
}
