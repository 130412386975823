import * as React from "react";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Typography from "@mui/material/Typography";

import { PRICE_LABEL_MAP, Price, PricesData } from "./types";

const simplifyPrice = (amount: string) =>
    amount.includes(".00") ? amount.split(".00")[0] : amount;

interface ButtonProps {
    price: Price;
    description: string;
    selected: boolean;
    onSelect: (lookupKey: string) => void;
    currencyFormatter: Intl.NumberFormat;
}

function PriceButton({
    price,
    description,
    currencyFormatter,
    selected,
    onSelect,
}: ButtonProps): React.JSX.Element {
    const monthAmount =
        price.recurring.interval === "year"
            ? ` (${simplifyPrice(currencyFormatter.format(price.unit_amount / 100 / 12))}/mo.)`
            : "";

    return (
        <ListItemButton
            selected={selected}
            onClick={() => onSelect(price.lookup_key)}
            sx={(t) => ({
                height: 110,
                flexDirection: "column",
                justifyContent: "center",
                borderWidth: 1,
                borderStyle: "solid",
                borderRadius: 8,
                my: 1,
                borderColor: t.palette.grey[800],
                background: t.palette.common.white,
                "&.Mui-selected, &.Mui-selected:hover": {
                    backgroundColor: t.palette.common.white,
                    borderColor: t.palette.primary.main,
                    borderWidth: 5,
                },
            })}
        >
            <Typography variant="h3" gutterBottom>
                {PRICE_LABEL_MAP[price.lookup_key]}
            </Typography>
            <Typography fontWeight={500} gutterBottom>
                {simplifyPrice(
                    currencyFormatter.format(price.unit_amount / 100),
                )}
                /{price.recurring.interval}
                {monthAmount}
            </Typography>
            <Typography>{description}</Typography>
        </ListItemButton>
    );
}

interface Props {
    selected: Price;
    onSelect: (lookupKey: string) => void;
    prices: PricesData;
}

export default function SignupPriceSelector({
    selected,
    prices,
    onSelect,
}: Props): React.JSX.Element {
    const currencyFormatter = React.useMemo(
        () =>
            new Intl.NumberFormat(undefined, {
                style: "currency",
                currency: prices.platform_tennis_daily.currency,
            }),
        [prices.platform_tennis_daily.currency],
    );

    return (
        <List>
            <PriceButton
                price={prices.platform_tennis_daily}
                description="Best for trying Volley out"
                selected={
                    selected.lookup_key ===
                    prices.platform_tennis_daily.lookup_key
                }
                onSelect={onSelect}
                currencyFormatter={currencyFormatter}
            />
            <PriceButton
                price={prices.platform_tennis_monthly}
                description="Great value for part time players"
                selected={
                    selected.lookup_key ===
                    prices.platform_tennis_monthly.lookup_key
                }
                onSelect={onSelect}
                currencyFormatter={currencyFormatter}
            />
            <PriceButton
                price={prices.platform_tennis_yearly}
                description="Best bang for your buck"
                selected={
                    selected.lookup_key ===
                    prices.platform_tennis_yearly.lookup_key
                }
                onSelect={onSelect}
                currencyFormatter={currencyFormatter}
            />
        </List>
    );
}
