import * as React from "react";
import { Link as RouterLink } from "react-router";

import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

export default function TermsDisclaimer() {
    const theme = useTheme();

    return (
        <Typography variant="caption">
            By continuing, you agree to Volley’s{" "}
            <Link
                color={theme.palette.info.main}
                component={RouterLink}
                target="_blank"
                to="//getvolley.com/termsofservice"
            >
                Terms of Use.
            </Link>{" "}
            Learn more about how we use and protect your data in our{" "}
            <Link
                color={theme.palette.info.main}
                component={RouterLink}
                target="_blank"
                to="//getvolley.com/privacy"
            >
                Privacy Policy.
            </Link>
        </Typography>
    );
}
