import * as React from "react";

import { InputBaseComponentProps } from "@mui/material/InputBase";

type Props = InputBaseComponentProps & {
    component: React.FC<InputBaseComponentProps>;
    options: Record<string, unknown>;
};

// https://github.com/mui/material-ui/issues/16037#issuecomment-498664873
const StripeInput = React.forwardRef<unknown, Props>(
    ({ component: Component, options, ...props }, ref): React.JSX.Element => {
        const elementRef = React.useRef<HTMLInputElement>(undefined);
        React.useImperativeHandle(ref, () => ({
            focus: () => elementRef.current?.focus(),
        }));
        const onReady = React.useCallback((element: HTMLInputElement) => {
            elementRef.current = element;
        }, []);
        return (
            <Component onReady={onReady} options={{ ...options }} {...props} />
        );
    },
);

StripeInput.displayName = "StripeInput";

export default StripeInput;
