import * as React from "react";
import { Link as RouterLink, Navigate, Routes, Route } from "react-router";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";

import fetchApi from "../../util/fetchApi";
import Loading from "../common/Loading";
import RequireAuth from "../common/RequireAuth";

import SignupAccount from "./SignupAccount";
import SignupEmail from "./SignupEmail";
import SignupPayment from "./SignupPayment";
import SignupPlans from "./SignupPlans";
import { Config } from "./types";

const volleyIconImg = new URL(
    "../../static/img/logo-volley-horizontal-white.png",
    import.meta.url,
).toString();

export default function Signup(): React.JSX.Element {
    const [stripePromise, setStripePromise] =
        React.useState<Promise<Stripe | null>>();
    const [errorMessage, setErrorMessage] = React.useState("");

    React.useEffect(() => {
        async function fetchStripePublishableKey() {
            const config = await fetchApi<Config>("/api/signup/config");
            setStripePromise(loadStripe(config.publishableKey));
        }

        fetchStripePublishableKey().catch((e: Error) => {
            setErrorMessage(e.message);
        });
    }, []);

    if (stripePromise === undefined) {
        return <Loading />;
    }

    return (
        <Elements stripe={stripePromise}>
            <AppBar
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, flexGrow: 1 }}
            >
                <Toolbar sx={{ justifyContent: "space-between" }}>
                    <IconButton
                        size="large"
                        color="inherit"
                        aria-label="back"
                        component={RouterLink}
                        to={-1 as unknown as string}
                    >
                        <ChevronLeftIcon fontSize="large" />
                    </IconButton>
                    <Box
                        component="div"
                        display="flex"
                        alignItems="center"
                        ml="-40px"
                    >
                        <IconButton
                            size="large"
                            edge="end"
                            color="inherit"
                            aria-label="home"
                            component={RouterLink}
                            to="/"
                        >
                            <img
                                src={volleyIconImg as string}
                                width="130"
                                alt="Volley logo"
                            />
                        </IconButton>
                    </Box>
                    <IconButton
                        size="large"
                        color="inherit"
                        aria-label="back"
                        component={RouterLink}
                        to="/"
                    >
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box
                component="main"
                sx={{ my: 4, background: "#fff", minHeight: "100vh" }}
            >
                {errorMessage && (
                    <Typography color="error.main">{errorMessage}</Typography>
                )}
                <Routes>
                    <Route path="*" element={<Navigate replace to="email" />} />
                    <Route path="email" element={<SignupEmail />} />
                    <Route path="account" element={<SignupAccount />} />
                    <Route path="plans" element={<SignupPlans />} />
                    <Route
                        path="payment"
                        element={
                            <RequireAuth>
                                <SignupPayment />
                            </RequireAuth>
                        }
                    />
                </Routes>
            </Box>
        </Elements>
    );
}
