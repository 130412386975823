import * as React from "react";
import { useNavigate } from "react-router";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import fetchApi from "../../util/fetchApi";
import { VolleyButton } from "../common/buttons";

import SignupPriceSelector from "./SignupPriceSelector";
import SignupPriceSummary from "./SignupPriceSummary";
import { Config, Invoice, Price, PricesData } from "./types";

export default function SignupPlans(): React.JSX.Element {
    const navigate = useNavigate();
    const [prices, setPrices] = React.useState<PricesData | null>(null);
    const [invoicePreview, setInvoicePreview] = React.useState<Invoice | null>(
        null,
    );
    const [selectedPrice, setSelectedPrice] = React.useState<Price | null>(
        null,
    );
    const [promoCode, setPromoCode] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    const isFreeForever =
        invoicePreview?.discount?.coupon.percent_off === 100 &&
        invoicePreview.discount.coupon.duration === "forever";

    React.useEffect(() => {
        async function fetchPrices() {
            const { prices: pricesData } =
                await fetchApi<Config>("/api/signup/config");
            setPrices(pricesData);
            setSelectedPrice(pricesData.platform_tennis_yearly);
        }
        fetchPrices().catch((err: Error) => {
            setErrorMessage(err.message);
        });
    }, []);

    const onSelectPrice = React.useCallback(
        (lookupKey: string) => {
            if (!prices) return;
            setSelectedPrice(
                (prices as unknown as Record<string, Price>)[lookupKey],
            );
            setInvoicePreview(null);
            setPromoCode("");
        },
        [prices],
    );

    const calculatePromoPrice = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setErrorMessage("");

            if (!selectedPrice) {
                return;
            }

            setLoading(true);
            try {
                const invoicePreviewData = await fetchApi<Invoice>(
                    "/api/signup/invoice-preview",
                    "POST",
                    {
                        priceId: selectedPrice.id,
                        promoCode: promoCode ?? undefined,
                    },
                );
                setInvoicePreview(invoicePreviewData);
            } catch (err: unknown) {
                const { message } = err as Error;
                if (message.indexOf("promo code") > -1) {
                    setErrorMessage(message);
                } else {
                    setErrorMessage(message);
                }
            } finally {
                setLoading(false);
            }
        },
        [selectedPrice, promoCode],
    );

    const onNext = React.useCallback(() => {
        if (!selectedPrice) return;
        if (isFreeForever) {
            navigate("../payment?state=checkout", {
                state: {
                    invoicePreview,
                    promoCode,
                    priceId: selectedPrice?.id,
                },
            });
        } else {
            navigate("../payment", {
                state: { priceId: selectedPrice?.id, promoCode },
            });
        }
    }, [selectedPrice, promoCode, invoicePreview, isFreeForever, navigate]);

    return (
        <Container
            maxWidth="xs"
            sx={{ background: "#d5dBed80", minHeight: "100vh" }}
        >
            <Toolbar />
            <Typography
                variant="h3"
                component="h2"
                textAlign="center"
                gutterBottom
            >
                Select Your Membership Plan
            </Typography>
            {selectedPrice && prices && (
                <SignupPriceSelector
                    selected={selectedPrice}
                    onSelect={onSelectPrice}
                    prices={prices}
                />
            )}
            <Card elevation={4} sx={{ mb: 2, px: 1, py: 2 }}>
                <Stack spacing={2}>
                    {!!prices && (
                        <TextField
                            key="promoCode"
                            label={
                                <Typography variant="caption">
                                    {invoicePreview?.discount
                                        ? "Code applied!"
                                        : "Have a promo code? Enter it here."}
                                </Typography>
                            }
                            id="promoCode"
                            name="promoCode"
                            value={invoicePreview?.discount ? "" : promoCode}
                            disabled={!!invoicePreview?.discount}
                            onChange={(e) =>
                                setPromoCode(e.currentTarget.value)
                            }
                            fullWidth
                            helperText={errorMessage || undefined}
                            error={!!errorMessage}
                            slotProps={{
                                input: {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button
                                                aria-label="validate promo code"
                                                variant="contained"
                                                onClick={calculatePromoPrice}
                                                loading={loading}
                                                disabled={
                                                    !!invoicePreview?.discount ||
                                                    !promoCode
                                                }
                                            >
                                                Apply
                                            </Button>
                                        </InputAdornment>
                                    ),
                                },
                            }}
                        />
                    )}
                </Stack>
            </Card>
            <Box component="div" pt={2}>
                <SignupPriceSummary
                    selectedPrice={selectedPrice}
                    invoicePreview={invoicePreview}
                />
            </Box>
            <Box component="div" py={8}>
                <VolleyButton type="button" onClick={onNext} disabled={loading}>
                    {isFreeForever
                        ? "Proceed To Confirmation"
                        : "Proceed To Payment"}
                </VolleyButton>
            </Box>
        </Container>
    );
}
